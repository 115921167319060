<template>
  <div>
    <h1>{{ msg }}</h1>
    <div class="div-centr" align="center">
      <span><b>OTP: </b></span>
      <input v-model="otp" text-align="center">
      <table class="gridtable">
        <tr>
          <th>Name</th>
          <th>State</th>
          <th>Time</th>
          <th>Result</th>
          <th>Action</th>
        </tr>
        <tr v-for="item in list " :key="item.Name">
          <td>{{item.Name}}</td>
          <td>
            <template>
              <span v-if="item.Success" class="green">Runing</span>
              <span v-if="!item.Success" class="red">Fail</span>
            </template>
          </td>
          <td>{{item.LastTime}}</td>
          <td>
            <div class="div-warp">
              {{item.Result}}
            </div>
          </td>
          <td>
            <button @click="restart(item.Name)"> Restart </button>
          </td>
        </tr>
      </table>
      <h1>{{ desc }}</h1>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      list: [],
      syncT: 0,
      otp: '',
      desc: ''
    }
  },
  mounted() {
    this.syncT = setInterval(() => {
      this.load()
    }, 30 * 1000);
    this.load()
  },
  methods: {
    load() {
      this.$axios({
        method: 'get',
        url: '/api/v1/result',
      }).then((response) => {
        this.list = response.data.data
        // debugger
      }).catch((error) => {
        alert(error.message)
      })
    },
    restart(name) {
      this.desc = 'restart [' + name + ']'
      let k = CryptoJS.MD5(this.otp).toString()
      let r = this.Encryptor(name, k, '')
      console.log(name, r, k)
      this.$axios({
        method: 'post',
        url: '/api/v1/restart',
        data: 'data=' + encodeURIComponent(r)
      }).then((response) => {
        console.log(JSON.stringify(response.data))
        this.desc = response.data.desc
        // debugger
      }).catch((error) => {
        alert(error.message)
      })
    },

    Encryptor(body, key, iv) {
      let i = CryptoJS.enc.Utf8.parse(iv || 'f9e49e7a68f3b510');
      let k = CryptoJS.enc.Utf8.parse(key.toUpperCase());
      let srcs = CryptoJS.enc.Utf8.parse(body);
      let enc = CryptoJS.AES.encrypt(srcs, k, {
        iv: i,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })

      let result = enc.ciphertext.toString(CryptoJS.enc.Base64)
      return result
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
table.gridtable {
  font-family: verdana, arial, sans-serif;
  font-size: 11px;
  color: #333333;
  border-width: 1px;
  border-color: #666666;
  border-collapse: collapse;
}
table.gridtable th {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #dedede;
}
table.gridtable td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #ffffff;
}

.div-warp {
  /* border: 1px solid #00f; */
  width: 700px;
  height: 60px;
  word-wrap: break-word;
}

.div-centr {
  margin: 0 auto;
}

.red {
  color: red;
}

.green {
  color: #42b983;
}

input {
  border: 1px solid #ccc;
  padding: 7px 0px;
  border-radius: 3px;
  padding-left: 5px;
  margin-bottom: 25px;
}

button {
  text-align: center;
  padding: 12px 20px;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}
</style>
